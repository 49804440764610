import {CDateRangePicker} from "@coreui/react-pro";
import {LanguageUtils} from "../../../utils/LanguageUtils";
import {CButton} from "@coreui/react";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import "./BbxDoubleDatePicker.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

interface BbxDoubleDatePickerProps {
    values: { dateStart: Date | null, dateEnd: Date | null };
    onDateStartChange: (date: Date | null) => void;
    onDateEndChange: (date: Date | null) => void;
}

export function BbxDateRangePicker({
                                        values,
                                        onDateStartChange,
                                        onDateEndChange
                                    }: BbxDoubleDatePickerProps) {
    const {t} = useTranslation();


    const customRanges = {
        [t('This month')]: [
            new Date(new Date().setDate(1)),
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        ],
        [t('Last month')]: [
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        ],
        [t('This year')]: [
            new Date(new Date().getFullYear(), 0, 1),
            new Date(new Date().getFullYear(), 11, 31),
        ],
        [t('Last year')]: [
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(new Date().getFullYear() - 1, 11, 31),
        ],
    };

    return (
        <div className={"input-group"}>
            <CDateRangePicker
                style={{width: "87%"}}
                placeholder={[t("StartDate"), t("EndDate")]}
                id="leadDateInput"
                locale={LanguageUtils.getLocale(true)}
                startDate={values.dateStart}
                endDate={values.dateEnd}
                ranges={customRanges}
                rangesButtonsVariant={"outline"}
                rangesButtonsColor={"secondary"}

                onStartDateChange={(date) => {
                    onDateStartChange(date);
                }}
                onEndDateChange={(date) => {

                    onDateEndChange(date);

                }}
                cleaner={false}
            />
            {
                <CButton
                    style={{
                        borderRadius: "0px",
                        border: "2px solid var(--border-blue)",
                        boxShadow: "none",
                        width: "60px"
                    }}
                    className="btn btn-secondary"
                    onClick={() => {
                        onDateStartChange(null);
                        onDateEndChange(null);
                    }}
                >
                    <FontAwesomeIcon icon={faXmark}/>
                </CButton>

            }
        </div>
    )
}