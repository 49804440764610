//React and Coreui Imports
import {CButton, CCard, CCardBody, CCardFooter, CCardHeader, CForm, CFormInput, CFormLabel} from "@coreui/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

//Stylesheets
import './Login.css';

//Assets
import logoBE from '../../Assets/bobex-white-165.png';
import logoNL from '../../Assets/bobexnl-white-165.png';
import logoCI from '../../Assets/CI_white.png';
import logoVBK from '../../Assets/verbouwkosten-logo.png';


//Services
import {UserDataInterface} from "../../Shared/Interfaces/UserData.interface";
import i18n from "i18next";
import AuthenticationRequest from "../../Data/request/AuthenticationRequestDTO";
import {accountService} from "../../Services/Helpers/AccountService";
import {GeneralRequestService} from "../../Services/Supplier/GeneralRequestService";
import {BbxToast} from "../../CustomHooks/ToastCustomComponentProps/BbxToast";
import {RouteUtils} from "../../utils/RouteUtils";
import {BbxToastType} from "../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import LanguageSwitcher from "../../Shared/Components/Header/LanguageSwitcher";
import {useLocation} from "react-router-dom";


/**
 * Displays the login form and eventually a toast if the login failed.
 * Request send to the backend to authenticate the user, set token and user object in local storage
 * and redirect to the right app depending on the user type.
 * @return {React.ReactElement}
 */
function Login(): React.ReactElement {
    const {t} = useTranslation();
    const userService = accountService.getUser();
    const location = useLocation();

    const [showPasswordResetForm, setShowPasswordResetForm] = useState<boolean>(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState<string>("");
    const [validated, setValidated] = useState(false);
    const [resetPasswordValidated, setResetPasswordValidated] = useState(false);
    const [credentials, setCredentials] = useState(new AuthenticationRequest());
    const [validLoginField, setValidLoginField] = useState<boolean>(true);
    const [validPasswordField, setValidPasswordField] = useState<boolean>(true);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState(BbxToastType.SUCCESS);
    const [toastMessage, setToastMessage] = useState("");


    function getLogo() {

        if (RouteUtils.getContext() === 'be') {
            return logoBE;
        } else if (RouteUtils.getContext() === 'nl') {
            return logoNL;
        } else if (RouteUtils.getContext() === 'ci') {
            return logoCI;
        } else if (RouteUtils.getContext() === 'vbk') {
            return logoVBK;
        }
        return logoBE;
    }

    useEffect(() => {
        if (userService !== null) {
            accountService.refreshToken().then((response) => {
                RouteUtils.redirectToUserPage(userService);
            }).catch((error) => {
                console.error("Token expired");
            });
        }
    }, []);

    useEffect(() => {
        if (location.state && location.state.showToast) {
            setToastType(location.state.toastType);
            setToastMessage(location.state.toastMessage);
            setShowToast(true);
        }
    }, [location.state]);


    const handleSubmit = async (event: any) => {

        event.preventDefault();

        if (credentials.loginId === "" && credentials.password === "" ) {
            showToastMessage(BbxToastType.ERROR, t("Please fill in all the fields"));
            setValidLoginField(false);
            setValidPasswordField(false);
            credentials.firstEntryLogin = false;
            credentials.firstEntryPassword = false;
            credentials.firstEntryTeamID = false;
            event.stopPropagation();
            return;
        }

        if (credentials.loginId === "" || credentials.loginId === undefined) {
            showToastMessage(BbxToastType.ERROR, t("Login is required"));
            setValidLoginField(false);
            credentials.firstEntryLogin = false;
            event.stopPropagation();
            return;
        }

        if (credentials.password === "" || credentials.password === undefined) {
            showToastMessage(BbxToastType.ERROR, t("Password is required"));
            setValidPasswordField(false);
            credentials.firstEntryPassword = false;
            event.stopPropagation();
            return;
        }


        setValidated(true);

        GeneralRequestService.login(credentials)
            .then((response) => {
                if (response.status === 200) {
                    accountService.saveToken(response?.data?.access_token);
                    accountService.saveRefreshToken(response?.data?.refresh_token);

                    //Set User
                    let user: UserDataInterface = {
                        loginId: credentials.loginId,
                        authority: response?.data?.authority,
                        role: response?.data?.role,
                        locale: response?.data?.locale,
                        languageId: response?.data?.languageId,
                        userID: response?.data?.userID,
                        isTeamMember: response?.data?.teamMember,
                        companyName: response?.data?.companyName,
                        firstname: response?.data?.firstname,
                        lastname: response?.data?.lastname,
                        companyPictureUrl: response?.data?.companyPictureUrl,
                        context: response?.data?.context,
                        teamMemberRole: response?.data?.teamMemberRole
                    }

                    accountService.saveUser(user);

                    let language;

                    if (accountService.getLanguageLocalStorage() === null) {
                        language = user.locale;
                    } else {
                        language = accountService.getLanguageLocalStorage().name;
                    }

                    i18n.changeLanguage(language);

                    const redirectPath = accountService.getRedirectAfterLogin();

                    if (redirectPath) {
                        localStorage.removeItem('redirectAfterLogin');
                        RouteUtils.redirectAfterLogin(redirectPath, user);
                    } else {
                        // Redirect to the right page depending on the user type
                        RouteUtils.redirectToUserPage(user);
                    }

                } else {
                    showToastMessage(BbxToastType.ERROR, t("The username or password you entered is incorrect"));
                    setValidated(false);
                    setValidLoginField(false);
                    setValidPasswordField(false);
                }
            }).catch((error) => {

            showToastMessage(BbxToastType.ERROR, t(error.response.data.detail));

            if (error.response.data.detail === "Unable to find user with username provided!") {
                setValidLoginField(false);
            }

            if (error.response.data.detail === "Password is not valid") {
                setValidPasswordField(false);
            }

            setValidated(false);
        });
    }

    const handleResetPassword = async (event: any) => {
        const form = event.currentTarget.closest("form");

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(resetPasswordEmail)) {
            event.preventDefault();
            showToastMessage(BbxToastType.ERROR, t("Please enter a valid email address"));
            setResetPasswordValidated(true);
            return;
        }

        setResetPasswordValidated(true);

        event.preventDefault();

        await accountService.forgotPassword(resetPasswordEmail).then(
            async result => {
                setResetPasswordEmail("");
                showToastMessage(BbxToastType.SUCCESS, t("An email has been sent to you with a link to reset your password"));
                setShowPasswordResetForm(false);
            }
        ).catch(error => {
            showToastMessage(BbxToastType.ERROR, t("Login global error message"));
            setResetPasswordValidated(false);
        });
    };

    const togglePasswordResetForm = () => {
        setShowPasswordResetForm(!showPasswordResetForm);
    };

    const handleLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({...credentials, loginId: e.target.value.trim().toLowerCase(), firstEntryLogin: false});

        setValidLoginField(true);
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({...credentials, password: e.target.value.trim(), firstEntryPassword: false});

        setValidPasswordField(true);
    }

    const showToastMessage = (type: BbxToastType, message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
    };

    return (
        <>
            <BbxToast
                autohide={true}
                visible={showToast}
                onClose={() => setShowToast(false)}
                title={toastType === BbxToastType.SUCCESS ? t('Success') : t('Error')}
                body={toastMessage}
                type={toastType}
            />

            <div className="login-page vw-100 vh-100 d-flex justify-content-center align-items-center">
                <section className="position-absolute">
                    <div className='air air1'></div>
                    <div className='air air2'></div>
                    <div className='air air3'></div>
                    <div className='air air4'></div>
                </section>
                <CCard className="login-card">
                    <CCardHeader className="p-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <img src={getLogo()} alt="bobex-logo" width="125" height="auto"/>
                            <LanguageSwitcher/>
                        </div>
                    </CCardHeader>
                    <CCardBody className="w-100 p-5">
                        {showPasswordResetForm ? (
                            <CForm
                                className=" password-reset-form m-auto d-flex flex-column"
                                noValidate
                                validated={resetPasswordValidated}
                                onSubmit={handleResetPassword}
                                method={"post"}
                            >
                                <div className="mb-3">
                                    <CFormLabel htmlFor="resetPasswordEmail">{t("Email")}</CFormLabel>
                                    <CFormInput
                                        type="email"
                                        id="resetPasswordEmail"
                                        value={resetPasswordEmail}
                                        onChange={(e) => setResetPasswordEmail(e.target.value)}
                                        placeholder={t("Enter your email")}
                                        required
                                        feedbackInvalid={t("Valid email adress")}
                                    />
                                </div>
                                <CButton type="submit" className="px-4 m-auto mt-4" color={"info"}>{t("Reset password")}</CButton>
                            </CForm>
                        ) : (
                            <CForm
                                className=" loginForm m-auto d-flex flex-column"
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                method={"post"}
                            >
                                <div className="mb-3">
                                    <CFormLabel htmlFor="username">{t("Username")}</CFormLabel>
                                    <CFormInput
                                        type="text"
                                        id="username"
                                        name="loginId"
                                        value={credentials.loginId || ''}
                                        onChange={handleLogin}
                                        placeholder={t("Your ID or email")}
                                        required
                                        valid={credentials.loginId !== ""}
                                        invalid={!credentials.firstEntryLogin && (credentials.loginId === "" || !validLoginField)}
                                        feedbackInvalid={t("Please provide a valid username")}
                                    />
                                </div>
                                <div className="mb-3">
                                    <CFormLabel htmlFor="password">{t("Password")}</CFormLabel>
                                    <CFormInput
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={credentials.password || ''}
                                        onChange={handlePassword}
                                        placeholder={t("Password")}
                                        required
                                        valid={credentials.password !== ""}
                                        invalid={!credentials.firstEntryPassword && (credentials.password === "" || !validPasswordField)}
                                        feedbackInvalid={t("Please provide a valid password")}
                                    />
                                </div>

                                <CButton type="submit" className="px-4 m-auto mt-4" color={"info"}>{t("Log in")}</CButton>
                            </CForm>
                        )}
                    </CCardBody>
                    <CCardFooter className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-column justify-content-center align-items-center w-75 mx-auto">
                            {showPasswordResetForm ? (
                                <p onClick={togglePasswordResetForm} className="teamLink">{t("Return")}</p>
                            ) : (
                                <p onClick={togglePasswordResetForm}
                                   className="teamLink">{t("Forgotten password ?")}</p>
                            )
                            }
                        </div>
                    </CCardFooter>
                </CCard>

            </div>
        </>
    )
}

export default Login;