import {requestService} from "../Helpers/RequestService";
import {MemberView} from "../../Data/view/MemberModel/MemberView";
import {SupplierScoreProjection} from "../../Data/SupplierScoreProjection";
import {PageRequestDTO} from "../../Data/request/PageRequestDTO";
import {PartnerSubmissionRequestDTO} from "../../Data/request/PartnerSubmissionRequestDTO";
import {ApiRoutes} from "../../ApiRoutes";
import {SurveyCountDTO} from "../../Data/statistic/SurveyCountDTO";
import {SurveyBySubmissionStatusDTO} from "../../Data/statistic/SurveyBySubmissionStatusDTO";
import {AccountManagerView} from "../../Data/view/MemberModel/AccountManagerView";
import {
    PartnerSubmissionCountPerRegionView
} from "../../Data/view/PartnerSubmissionModel/PartnerSubmissionCountPerRegionView";
import {
    PartnerSubmissionCountPerBusinessView
} from "../../Data/view/PartnerSubmissionModel/PartnerSubmissionCountPerBusinessView";
import {
    PartnerSubmissionCountPerYearMonthView
} from "../../Data/view/PartnerSubmissionModel/PartnerSubmissionCountPerYearMonthView";
import {ApiKeyRequestDTO} from "../../Data/request/ApiKeyRequestDTO";
import {PartnerSubmissionView} from "../../Data/view/PartnerSubmissionModel/PartnerSubmissionView";
import {Page} from "../../Data/Page";
import {BbxRequest} from "../../Data/request/BbxRequest";
import {TokenDTO} from "../../Data/TokenDTO";
import {ExpertRoleView} from "../../Data/view/ExpertRoleModel/ExpertRoleView";
import {
    PartnerSubmissionCountPerDayView
} from "../../Data/view/PartnerSubmissionModel/PartnerSubmissionCountPerDayView";
import {ProtestSubmissionUpdateDTO} from "../../Data/request/Update/ProtestSubmissionUpdateDTO";
import {ProtestRemoveAttachentUpdateDTO} from "../../Data/request/Update/ProtestRemoveAttachentUpdateDTO";
import {ExpertBusinessTypeView} from "../../Data/view/ExpertBusinessType/ExpertBusinessTypeView";
import {SelectOptionView} from "../../Data/view/SelectOption/SelectOptionView";
import RequestUtil from "../../utils/RequestUtil";
import {ExpertRoleRegionZipsView} from "../../Data/view/ExpertRoleModel/ExpertRoleRegionZipsView";

async function getPartnerRfps(
    partnerSubmissionRequestDTO: BbxRequest<PartnerSubmissionRequestDTO>,
    abortSignal?: AbortSignal
): Promise<Page<PartnerSubmissionView>> {


    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_LEADS, RequestUtil.prepareRequest(partnerSubmissionRequestDTO), {
        signal: abortSignal
    })
        .then((response) => {
            return response.data as Page<PartnerSubmissionView>
        });
}

async function getMyBusinesses(): Promise<SelectOptionView[]> {

    return await requestService.get('/v1/supplier/businesses', {})
        .then((response) => {
            return response.data.map((type: any) => new SelectOptionView(type)) as SelectOptionView[];
        });
}

async function getMyScore(): Promise<SupplierScoreProjection> {


    return await requestService.get('/v1/supplier/score', {})
        .then((response) => {
            return response.data as SupplierScoreProjection;
        });
}

async function getSupplierData(): Promise<MemberView> {
    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_DATA, {})
        .then((response) => {
            return response.data as MemberView;
        });
}


async function getLeadNumberPerYearMonth(request: PartnerSubmissionRequestDTO): Promise<PartnerSubmissionCountPerYearMonthView[]> {
    request.partnerRfpRequest.submissionDateStart = null;
    request.partnerRfpRequest.submissionDateEnd = null;
    return await requestService.post(ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_YEAR_MONTH, request)
        .then((response) => {
            return response.data as PartnerSubmissionCountPerYearMonthView[];
        });
}

/* RequestService.getRequest('http://localhost/api-be/v1/supplier/expertbusinesstypes', {}).then((response) => {
            setExpertBusiessTypes(response.content as ExpertBusinessTypeView[])
        });*/

async function getLeadNumberPerDay(request: PartnerSubmissionRequestDTO): Promise<PartnerSubmissionCountPerDayView[]> {
    return await requestService.post(ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_DAY, request)
        .then((response) => {
            return response.data as PartnerSubmissionCountPerDayView[];
        });
}


async function getExpertBusinessTypes(options: BbxRequest<Object>): Promise<Page<ExpertBusinessTypeView>> {

    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_EXPERT_BUSINESS_TYPES, RequestUtil.prepareRequest(options))
        .then((response) => {
            return response.data as Page<ExpertBusinessTypeView>;
        });

}

export async function generateApiKey(apiKeyRequestDTO: ApiKeyRequestDTO): Promise<string> {

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_APIKEYS + `/create`, apiKeyRequestDTO)

    return response.data as string;
}

async function deleteApiKey(tokenId: number): Promise<void> {
    try {
        await requestService.del(ApiRoutes.REACT_APP_URL_SUPPLIER_APIKEYS + `/${tokenId}/delete`, {})
    } catch (e) {
        throw e;
    }
}

async function getTokensAPIAccessByUserId(currentPage: number): Promise<Page<TokenDTO>> {
    let pageRequestDTO = new PageRequestDTO();
    pageRequestDTO.page = currentPage;

    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_APIKEYS, pageRequestDTO)
        .then((response) => {
            return response.data as Page<TokenDTO>;
        });
}

async function getLeadsPerRegion(): Promise<PartnerSubmissionCountPerRegionView[]> {
    if (!ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_PER_REGION) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_PER_REGION, {})
        .then((response) => {
            return response.data as PartnerSubmissionCountPerRegionView[];
        });
}

async function getLeadsPerYearMonthAndSubmissionStatus(): Promise<any> {
    if (!ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_YEAR_MONTH_AND_SUBMISSION_STATUS) {
        throw new Error("REACT_APP_URL_STATISTICS_LEAD_YEAR_MONTH_AND_SUBMISSION_STATUS is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_YEAR_MONTH_AND_SUBMISSION_STATUS, {})
        .then((response) => {
            return response.data as Record<string, Object>[];
        });
}

async function getLeadsPerDayAndSubmissionStatus(): Promise<any> {
    if (!ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_DAY_AND_SUBMISSION_STATUS) {
        throw new Error("REACT_APP_URL_STATISTICS_LEAD_DAY_AND_SUBMISSION_STATUS is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_DAY_AND_SUBMISSION_STATUS, {})
        .then((response) => {
            return response.data as Record<string, Object>[];
        });
}


async function getLeadsPerBusinessType(): Promise<PartnerSubmissionCountPerBusinessView[]> {
    if (!ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_PER_BUSINESS_TYPE) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_STATISTICS_LEAD_PER_BUSINESS_TYPE, {})
        .then((response) => {
            return response.data as PartnerSubmissionCountPerBusinessView[];
        });
}

async function getSurveysStatistics(surveyCountDTO: SurveyCountDTO): Promise<SurveyCountDTO> {
    if (!ApiRoutes.REACT_APP_URL_STATISTICS_SURVEYS) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }
    return await requestService.get(ApiRoutes.REACT_APP_URL_STATISTICS_SURVEYS, surveyCountDTO)
        .then((response) => {
            return response.data[0] as SurveyCountDTO;
        });
}

async function getSurveysBySubmissionStatus(): Promise<SurveyBySubmissionStatusDTO[]> {
    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_SURVEYS_BY_SUBMISSION_STATUS) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }
    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_SURVEYS_BY_SUBMISSION_STATUS, {})
        .then((response) => {
            return response.data;
        });
}

async function getAccountManager(): Promise<AccountManagerView> {
    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT_MANAGER) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }
    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_ACCOUNT_MANAGER, {})
        .then((response) => {
            return response.data;
        });
}

async function getExpertRole(): Promise<ExpertRoleView> {
    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_EXPERTROLE) {
        throw new Error("REACT_APP_URL_SUPPLIER_EXPERTROLE is not defined");
    }
    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_EXPERTROLE, {})
        .then((response) => {
            return response.data;
        });

}

async function getExpertRoleDefaultRegionZips(): Promise<ExpertRoleRegionZipsView> {
    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_EXPERTROLE ) {
        throw new Error("REACT_APP_URL_SUPPLIER_EXPERTROLE is not defined");
    }
    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_EXPERTROLE + '/expert-role-default-zips', {})
        .then((response) => {
            return response.data;
        });

}

async function sendProtestRequest(protestSubmissionRequestDTO: ProtestSubmissionUpdateDTO): Promise<any> {
    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_UPDATE_PROTEST) {
        throw new Error("REACT_APP_URL_SUPPLIER_PROTEST is not defined");
    }
    return await requestService.post(
        ApiRoutes.REACT_APP_URL_SUPPLIER_UPDATE_PROTEST,
        protestSubmissionRequestDTO,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
        .then((response) => {
            return response.data;
        });

}

async function removeProtestAttachementRequest(protestSubmissionRequestDTO: ProtestRemoveAttachentUpdateDTO): Promise<any> {
    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_REMOVE_PROTEST_ATTACHMENT) {
        throw new Error("REACT_APP_URL_SUPPLIER_PROTEST is not defined");
    }
    return await requestService.post(
        ApiRoutes.REACT_APP_URL_SUPPLIER_REMOVE_PROTEST_ATTACHMENT,
        protestSubmissionRequestDTO,
    )
        .then((response) => {
            return response.data;
        });

}


async function downloadLeadsCsvForSupplier(partnerSubmissionRequestDTO: PartnerSubmissionRequestDTO): Promise<any> {

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_LEADS + `/download-csv-supplier`, partnerSubmissionRequestDTO, {
        responseType: 'blob',
    })
    return response;
}

export const SupplierRequestService = {
    getPartnerRfps,
    getMyBusinesses,
    getMyScore,
    getSupplierData,
    getLeadNumberPerYearMonth,
    getExpertBusinessTypes,
    getTokensAPIAccessByUserId,
    deleteApiKey,
    getLeadsPerRegion,
    getLeadsPerBusinessType,
    getLeadsPerYearMonthAndSubmissionStatus,
    getLeadsPerDayAndSubmissionStatus,
    generateApiKey,
    getSurveysStatistics,
    getSurveysBySubmissionStatus,
    getAccountManager,
    getExpertRole,
    getLeadNumberPerDay,
    downloadLeadsCsvForSupplier,
    sendProtestRequest,
    removeProtestAttachementRequest,
    getExpertRoleDefaultRegionZips
}